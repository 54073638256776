var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"investor-bar py-2.5",class:("" + (_vm.showOnTopPlaceholderIds && !!_vm.showOnTopPlaceholderIds.length ? '' : 'mb-4')),attrs:{"id":"investor-bar-content"}},[_c('div',{staticClass:"container px-4 m-auto"},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"investor-bar__button left-item py-1.5"},[_c('a',{attrs:{"href":_vm.investorHomePage}},[_c('span',{staticClass:"pr-1"},[_vm._v("Investor")]),_vm._v(" "),_c('svg',{staticClass:"hidden xl:block",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"10","fill":"none"}},[_c('use',{attrs:{"href":"#arrow-right"}})])])]),_vm._v(" "),_c('div',{staticClass:"investor-bar__item-content flex justify-between py-1"},_vm._l((_vm.items),function(ref){
var name = ref.name;
var last = ref.last;
var percent = ref.percent;
var url = ref.url;
var changed = ref.changed;
var decreased = ref.decreased;
return _c('a',{key:name,class:['investor-bar__item rounded my-px', decreased ? 'investor-bar__item-decreased' : 'investor-bar__item-increased'],attrs:{"href":url}},[_c('div',{staticClass:"investor-bar__item-holder pl-2 pr-2"},[_c('span',{staticClass:"investor-bar__item-name mr-1"},[_vm._v(_vm._s(name))]),_vm._v(" "),_c('span',{staticClass:"investor-bar__item-value"},[_vm._v(_vm._s(last))])]),_vm._v(" "),(changed)?_c('div',{class:['investor-bar__item-difference-holder pl-2 pr-2 py-1.5', decreased ? 'investor-bar__item-difference-holder--decreased' : 'investor-bar__item-difference-holder--increased']},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"8","height":"7","fill":"none"}},[_c('use',{attrs:{"href":"#triangle"}})]),_vm._v(" "),(percent)?_c('span',{class:['investor-bar__item-difference pl-1', 'investor-bar__item-percentage', decreased ? 'investor-bar__item-difference--decreased' : 'investor-bar__item-difference--increased']},[_vm._v("\n              "+_vm._s(percent)+"%\n            ")]):_vm._e()]):_vm._e()])}),0),_vm._v(" "),_c('div',{staticClass:"investor-bar__button p-px pr-4"},[_c('a',{attrs:{"href":_vm.investorEditPage}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"34","height":"34","fill":"none"}},[_c('use',{attrs:{"href":"#edit"}})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }