//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapState, mapMutations} from 'vuex';
import globalConfig from '~/config/global_config.json';

export default {
  name: 'InvestorTicker',
  data() {
    return {
      investorHomePage: 'https://www.dn.no/investor',
      investorEditPage: 'https://www.dn.no/investor',
      isInitialData: true,
      socket: null,
    }
  },
  computed: {
    ...mapState(['investorTicker', 'topEditionResponse', 'loginStatus']),
    showOnTopPlaceholderIds() {
      return this.topEditionResponse.showOnTopPlaceholderIds || [];
    },
    items() {
      return this.investorTicker.items || [];
    }
  },
  errorCaptured: function (err) {
    console.error(`Caught error from children of InvestorTicker component:: ${err.message}`);
  },
  created() {
    if (process.client) {
      this.socket = new WebSocket('wss://inv-watchlist-api.dn.no/Pulse/');
      this.socket.onopen = this.onOpen;
      this.socket.onmessage = this.onMessage;
    }
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  },
  methods: {
    ...mapMutations(['setInvestorTicker']),
    // eslint-disable-next-line no-unused-vars
    onOpen(openEvent) {
      this.isInitialData = true;
      const reqData = this.loginStatus.isLoggedIn ? {
        'seq': 0,
        'd': {
          '_t': 'watchlist',
          'id': this.loginStatus.ncpIdHash
        }
      } : {
        'seq': 0,
        'd': {
          '_t': 'watchlist'
        }
      }
      this.socket.send(JSON.stringify(reqData));
    },
    onMessage(messageEvent) {
      this.mapInvestorTicker(JSON.parse(messageEvent.data));
    },
    indexOfAddedItem(items, quant) {
      let index = -1;
      for (let i = 0; i < items.length; i++) {
        if (items[i].quant === quant) {
          index = i;
          break;
        }
      }
      return index;
    },
    calculatedLastValue(quant, last) {
      const currencyMultiplier = require('../../config/currency_multiplier.json');
      const multiplier = currencyMultiplier[quant] || 1;
      return last * multiplier;
    },
    toFixed(num, digits){
      return num.toFixed(digits) * 1;
    },
    getMappedItem(key, value) {
      const item = {
        'quant': key,
        'time': value.time,
        'last': new Intl.NumberFormat('nb-NO', { minimumFractionDigits: 2 }).format(this.toFixed(this.calculatedLastValue(key, value.last), 2)),
        'percent': value.percent ? new Intl.NumberFormat('nb-NO', { minimumFractionDigits: 2 }).format(this.toFixed(value.percent, 2)) : null,
        'changed': !!value.percent,
        'increased': value.percent ? value.percent > 0 : false,
        'decreased': value.percent ? value.percent < 0 : false
      };
      if (value.name) item.name = value.name;
      if (value.url) item.url = value.url;
      return item;
    },
    isItemChanged(prevItem, newItem) {
      return prevItem.last !== newItem.last || prevItem.percent !== newItem.percent;
    },
    mapInvestorTicker(data) {
      if (Object.keys(data)[0] === 'seq' && Object.values(data)[0] === 0) return;
      let mappedItems = [...this.items];
      let isChanged = false;
      if (this.isInitialData) {
        this.isInitialData = false;
        mappedItems = [];
      }
      Object.entries(data).forEach(([key, value]) => {
        if (value.last) {
          const item = this.getMappedItem(key, value);
          const index = this.indexOfAddedItem(mappedItems, key);
          if (index === -1) {
            isChanged = true;
            mappedItems.push(item);
          } else if (this.isItemChanged(mappedItems[index], item)) {
            isChanged = true;
            const targetItem = {...mappedItems[index]};
            mappedItems.splice(index, 1, Object.assign(targetItem, item));
          }
        }
      });
      if (isChanged) {
        if (mappedItems.length > 6) {
          mappedItems.splice(6, mappedItems.length - 6);
        }
        this.setInvestorTicker({
          'items': mappedItems
        });
      }
    }
  },
}
